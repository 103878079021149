import React from "react";
import Layout from "../components/layout";
import Img from "gatsby-image";
import {graphql, StaticQuery} from "gatsby";

const Facts = () => (
    <Layout>
        <StaticQuery
            query={graphql`
              query infoQuery {
                file(relativePath: {eq: "info.json"}) {
                  childDataJson {
                    info {
                      Q
                      A
                    }
                  }
                }
                winner:file(relativePath: {eq: "winner.jpg"}) {
                  childImageSharp {
                    fixed(width: 640, height: 960) {
                      src
                      width
                      height
                      srcSet
                    }
                  }
                }
              }
            `}
            render={data => {

              return (
                    <>
                        <h1>NEWS</h1>
                        <div className="body">
                            <div className='endgame'>
                                <Img fixed={data.winner.childImageSharp.fixed}/>
                                {data.file.childDataJson.info.map((item, key) => (
                                    <div className='info' key={key}>
                                        <h3>{item.Q}</h3>
                                        <span>{item.A}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                );
            }}
        />
    </Layout>
);

export default Facts;
